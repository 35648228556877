<template>
	<LoginLayout
		form-title="Forgotten password"
		portal-name="Administration Portal"
	>
		<template #form>
			<span
				v-if="emailLinkSent"
			>
				If the email you have supplied exists in Cassie then we will send an email to that address with a link that allows you to reset your password.
			</span>
			<validation-observer
				v-else
				#default="{ handleSubmit }"
				class="cassie-vertical-sm"
			>
				<TextField
					v-model="email"
					label="Email Address *"
					rules="required|email"
				/>
				<v-card-actions class="pa-0">
					<TextButton @click="$router.push('login')">
						Cancel
					</TextButton>
					<v-spacer />
					<PrimaryActionButton @click="handleSubmit(restorePassword)">
						Submit
					</PrimaryActionButton>
				</v-card-actions>
			</validation-observer>
		</template>
	</LoginLayout>
</template>

<script>
import LoginLayout from '../../../../../shared/layouts/login-layout.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import { sendAdminPortalResetPasswordEmailApi } from '../../../../../shared/utils/api/forgotten-password.js'
import TextField from '../../../../../shared/components/text-field.vue'

export default {
	components: { TextField, LoginLayout, PrimaryActionButton, TextButton },
	data () {
		return {
			username: '',
			email: '',
			emailLinkSent: false
		}
	},
	computed: {
		formTitle () {
			const formTitleText = !this.emailLinkSent ? 'Forgotten password' : 'Your password reset email has been sent'
			return formTitleText
		}
	},
	methods: {
		async restorePassword () {
			const { email } = this
			try {
				await sendAdminPortalResetPasswordEmailApi({ email })
				this.emailLinkSent = true
			} catch (error) {
				if (error.response && error.response.status === 403) {
					this.$router.push({ name: 'direct-login-not-allowed' })
				}
			}
		}
	}
}
</script>
